<template>
  <FocusTrap>
    <div class="card" id="saleinvoice_card">

      <WindowTitleBar title="Sale Invoice Creation" @loadData="loadData" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>


      <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Buyer</label>
            <select id="cmbBuyer" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.buyer_id" @change="enableAddButton" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Type</label>
            <select class="form-control select" required="" v-if="voucher"  v-model="voucher.type" @change="enableAddButton" >
              <option value="404">B2B - Local</option>
              <option value="405">B2C - Local</option>
              <option value="406">Export</option>
              <option value="407">B2B - Other State</option>
              <option value="408">B2C - Other State</option>
              <option value="409">B2C - Local Exempted</option>
            </select>
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Ref No</label>
            <input type="text" class="form-control" placeholder="Ref No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.ref_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Invoice Date</label>
            <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" placeholder="Invoice Date" >
          </div>
        </div>

      </div>

      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Item</th>
              <th style="width:100px; text-align: right;">GST%</th>
              <th style="width:100px; text-align: right;">Rate</th>
              <th style="width:75px; text-align: right;">Qty</th>
              <th style="width:100px; text-align: right;">Taxable</th>
              <th style="width:100px; text-align: right;">IGST</th>
              <th style="width:100px; text-align: right;">CGST</th>
              <th style="width:100px; text-align: right;">SGST</th>
              <th style="width:125px; text-align: right;">Net Amount</th>
              <th style="width:30px; padding: 0px;">Action</th>
            </tr>

            </thead>
            <tbody v-if="voucher" v-for="(detail,index) in voucher.list" >
              <SaleInvoiceDetailRow :state="voucher.buyer.state_id" :vchtype="voucher.type"  :items="detailItems" :myindex="index" :myrow="detail" v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow"  > </SaleInvoiceDetailRow>
            </tbody>
            <tfoot>
            <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow"  :disabled="addRowButtonDisabled">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" ><span style="padding: 0px;">{{ rowQtyTotal }}</span></td>
              <td class="text-right" >&#8377;<span style="padding: 0px;">{{ indianFormat(rowTaxableTotal) }}</span></td>
              <td class="text-right" >&#8377;<span style="padding: 0px;">{{ indianFormat(rowIgstTotal) }}</span></td>
              <td class="text-right" >&#8377;<span style="padding: 0px;">{{ indianFormat(rowCgstTotal) }}</span></td>
              <td class="text-right" >&#8377;<span style="padding: 0px;">{{ indianFormat(rowSgstTotal) }}</span></td>
              <td class="text-right" >&#8377;<span style="padding: 0px;">{{ indianFormat(rowAmountTotal) }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <h3 style="font-size: 48px;color: blue">
            <!--voucher.gross_amount-->
            &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(voucher.round_off) ) }}</span>
          </h3>
        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">
            <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
            <div class="col-md-8">
              <input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off" >
            </div>
          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="perform_save_saleinvioce">Save</button>
          <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import numeral from 'numeral'
  import GstPanel from '@/components/v1/gst/GstPanel.vue'
  import SaleInvoiceDetailRow from './SaleInvoiceDetailRow'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'SaleInvoiceForm',
    components: {
      SaleInvoiceDetailRow,
      WindowTitleBar
    },
    store,
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    beforeMount () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      if( this.myvoucher.id > 0 ) {
        var data = [];
        const request = async () => {
          const response = await fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/item/after/999`, requestOptions);

          const json = await response.json();
          data = json.data;
          if (_.isArray(data)) {
            data.forEach(function (itm) {
              self.$data.items.set(itm.id, itm);
            });
          }
          this.voucher = this.myvoucher;
          this.sumRowAmountTotal();

          this.voucher.round_off = this.voucher.round_off.toFixed(2);

        }
        request();
        self.$data.addRowButtonDisabled=false;
      }
    },
    data () {
      return {
        addRowButtonDisabled:true,
        readonly: false,
        ledgers:[],
        detailItems:[],
        items : new Map(),
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowCgstTotal:0.0,
        rowSgstTotal:0.0,
        rowTaxableTotal:0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
      }
    },
    created () {
      const self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      self.loadData();

      // self.$data.addRowButtonDisabled = true;
      if (self.$data.voucher.doc_date === '0001-01-01'){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }

      $('#cmbBuyer').focus();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      setTitle() {
        return this.voucher.id == 0 ? "Sale Invoice Creation" : "Doc No: " + this.voucher.doc_no + "  Sale Invoice Updation";
      },
      closeThis () {
        if (this.voucher.id > 1) {
          this.$emit('saleinvoice_window_closed');
          this.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
        } else {
          this.$router.push('/');
        }
      },
      enableAddButton(){
        const self = this;
        try {
          self.ledgers.forEach(function (ldgr) {
            if (self.$data.voucher.buyer_id == ldgr.id) {
              self.$data.voucher.buyer = JSON.parse(JSON.stringify(ldgr));
            }
          });

          this.$data.voucher.list = [];
          this.$data.addRowButtonDisabled = (this.$data.voucher.buyer_id > 1 && this.$data.voucher.type == 0);
        }catch (e) {
          alert(e);
        }
      },
      addRow(){
        try {
          const self = this;
          self.$data.voucher.list.push(JSON.parse('{"idx":0,"branch":{"id":1,"name":"","street":"","city":"","pincode":"","state_id":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"hsn":"","item":{"id":1,"nature":0,"ledger_group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","process":{"id":1,"name":"","nature":0,"p_name":"","sys_obj":false},"group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":0,"name":"","digits":0},"subunit":{"id":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"category":{"id":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"style":{"id":0,"name":"","p_name":""},"unit":{"id":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":1,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","wgt":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'));

          setTimeout(function(){
            $("tbody>tr").last().find("td:eq(1) select").focus();
          }, 99);

        }catch (e) {
          alert(e);
        }
      },
      onrow_change(rowIndex, colIndex) {
        const self = this;
        let tax_value = 0;

        let detail = self.$data.voucher.list[rowIndex];
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {

          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowSgstTotal += parseFloat(detail.sgst_amt);
          self.$data.rowCgstTotal += parseFloat(detail.cgst_amt);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowSgstTotal += parseFloat(detail.sgst_amt);
          self.$data.rowCgstTotal += parseFloat(detail.cgst_amt);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },
      clear(){
        $('#btnSave').prop('disabled', false);
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
        self.$data.addRowButtonDisabled = true;
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        $('#cmbBuyer').focus();
      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/customers`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        if( self.$data.items.size == 0 ){
          //Fetch Detail Ledgers
          self.$data.detailItems = []
          self.$data.items = new Map();
          fetch(`${process.env.VUE_APP_ROOT_API}api/items`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              if( _.isArray(resp.data)){
                self.$data.detailItems = resp.data;

                self.$data.detailItems.forEach(function (itm) {
                  self.$data.items.set(itm.id, itm);
                });
              }

            }else{
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          });
        }
      },
      perform_save_saleinvioce(){
        const self = this;

        try{

          $('#saleinvoice_card').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
          myvoucher.finyear = self.$store.state.user.finyear;
          myvoucher.type = parseInt(self.$data.voucher.type);
          myvoucher.eway_date = moment().format('YYYY-MM-DD');
          myvoucher.eway_bil_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD');

          myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
          myvoucher.ref_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

          myvoucher.round_off = parseFloat(self.$data.voucher.round_off);
          myvoucher.gross_amount = self.$data.rowAmountTotal;
          myvoucher.net_amount = myvoucher.gross_amount + myvoucher.round_off;
          myvoucher.brn_id = parseInt(self.$store.state.user.branch.id);

          myvoucher.list.forEach(function (detail){
            detail.branch.id = parseInt(self.$store.state.user.branch.id);
            detail.order_no = 1;//parseInt(detail.order_no);
            detail.work_order = 1;//parseInt(detail.work_order);
            detail.gst_rate = parseFloat(detail.gst_rate);
            detail.qty_txt = detail.qty.toString();
            detail.qty = parseFloat(detail.qty);
            detail.unit.id = 1;
            detail.rate = parseFloat(detail.rate);
            detail.taxable_amt = parseFloat(detail.taxable_amt);

            detail.igst_amt = parseFloat(detail.igst_amt);
            detail.cgst_amt = parseFloat(detail.cgst_amt);
            detail.sgst_amt = parseFloat(detail.sgst_amt);

            detail.tax_amt = parseFloat(detail.tax_amt);
            detail.net_amt = parseFloat(detail.net_amt);
          });


          const requestOptions = {
            method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT' ),
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };

          console.log(JSON.stringify(myvoucher));

          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}api/sale`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              swal({title: 'Great', type: 'success', text: resp.msg });
              $('#saleinvoice_card').unblock();
              self.$emit('saleinvoice_saved', resp.data);
            }else{
              $('#btnSave').prop('disabled', false);
              $('#saleinvoice_card').unblock();
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            $('#saleinvoice_card').unblock();
            $('#btnSave').prop('disabled', false);
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          });

        }catch (e) {

        }finally {

        }



      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
