<template>
  <tr style="padding: 0px;">
    <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

    <td style="padding: 0px;">
      <select class="form-control" v-if="detail" @change="onItemChanged(detail.item)" v-model="detail.item">
        <option v-for="item in items" v-bind:value="item">
          {{ item.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px; width: 30px;" >
      <select class="form-control" v-if="detail" v-model="detail.gst_rate" @change="rowValueEqualizer(index, 2)" disabled>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 4)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 5)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.igst_amt" :disabled="isIntegrated"  @change="rowValueEqualizer(index, 6)"/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.cgst_amt" :disabled="isState" @change="rowValueEqualizer(index, 7)"/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.sgst_amt" :disabled="isState" @change="rowValueEqualizer(index, 8)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import ItemBox from '@/components/core/ItemBox.vue'
import numeral from 'numeral'
export default {
  name: 'SaleInvoiceDetailRow',
  store,
  components: {
    ItemBox
  },
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_cd":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"hsn":"","item":{"id":"","code":1,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","process":{"id":"","code":1,"name":"","nature":0,"p_name":"","sys_obj":false},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":"","code":1,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","wgt":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}')
    },
    myindex: {
      type: Number,
      default: () => 0
    },
    items: {
      type: Map,
      default: () => new Map()
    },
    vchtype: {
      default: () => 0
    },
    state: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      detail: JSON.parse('{"idx":0,"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_cd":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"hsn":"","item":{"id":"","code":1,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","process":{"id":"","code":1,"name":"","nature":0,"p_name":"","sys_obj":false},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":"","code":1,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","wgt":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'),
      index: 0,
      isIntegrated: true,
      isState: false
    }
  },
  beforeMount () {
    this.detail = this.myrow
    this.index = this.myindex

    this.detail.taxable_amt = this.detail.taxable_amt.toFixed(2)
    this.detail.rate = this.detail.rate.toFixed(2)
    this.detail.igst_amt = this.detail.igst_amt.toFixed(2)
    this.detail.cgst_amt = this.detail.cgst_amt.toFixed(2)
    this.detail.sgst_amt = this.detail.sgst_amt.toFixed(2)
    this.detail.net_amt = this.detail.net_amt.toFixed(2)
  },
  mounted () {
    this.checkIGST()
  },
  methods: {
    onItemChanged (item) {
      const self = this;
      self.$data.detail.item_id = item.id;
      self.$data.detail.gstrate = item.group.gstrate;
      console.log(JSON.stringify(item));
      self.rowValueEqualizer(self.$data.detail.idx, 1);
    },
    removeRow (idx) {
      this.$emit('onRemoveRow', idx);
    },
    calculate_gst (detail) {
      let taxrate = detail.gst_rate;

      let tax_value = Math.round(detail.qty * detail.rate * (taxrate / 100) * 100);
      if (tax_value % 2 !== 0) {
        tax_value++;
      }
      tax_value = tax_value / 100;

      detail.tax_amt = tax_value;
      if (this.$props.state == 0 || (this.$props.state == store.state.user.branch.state_cd)) {
        detail.igst_amt = 0;
        detail.sgst_amt = tax_value / 2;
        detail.cgst_amt = tax_value / 2;
      } else {
        detail.igst_amt = tax_value;
        detail.sgst_amt = 0;
        detail.cgst_amt = 0;
      }
    },
    checkIGST () {
      if (this.$props.state == 0 || (this.$props.state == store.state.user.branch.state_cd)) {
        this.$data.isIntegrated = true;
        this.$data.isState = false;
      } else {
        this.$data.isIntegrated = false;
        this.$data.isState = true;
      }
    },
    rowValueEqualizer (rowIndex, colIndex) {
      const self = this;
      let tax_value = 0;

      let detail = self.$data.detail
      switch (colIndex) {
        case 1:
          // detail.item = self.$data.items.get(detail.item.code);
          detail.gst_rate = parseFloat(detail.item.group.gstrate);
          break
        case 2: // GST Rate
          detail.gst_rate = parseFloat(detail.item.group.gstrate);
          break
        case 3:// Product Rate
          detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
        case 4:// Product Qty

          detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
        case 5:// Taxable value
          detail.rate = numeral(parseFloat(detail.taxable_amt) / parseFloat(detail.qty)).format('0.00');
          self.calculate_gst(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
        case 6:
        case 7:
        case 8:
          detail.tax_amt = numeral(parseFloat(detail.igst_amt) + parseFloat(detail.cgst_amt) + parseFloat(detail.sgst_amt)).format('0.00');
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
      }
      self.$emit('onrow_change', rowIndex, colIndex);
    }

  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
